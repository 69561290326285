/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import ConfigurationContext from "../../src/context/Configuration";

const ConfigurableValues = () => {
  const configuration = useContext(ConfigurationContext);
  const SERVER_URL = "https://api.stubrn.al/";
  const WS_SERVER_URL = "wss://api.stubrn.al/";
  //const SERVER_URL = 'http://136.244.93.13:8001'
  //const WS_SERVER_URL = 'ws://136.244.93.13:8001'
  const GOOGLE_CLIENT_ID = configuration.webClientID;
  const STRIPE_PUBLIC_KEY = configuration.publishableKey;
  const PAYPAL_KEY = configuration.clientId;
  const GOOGLE_MAPS_KEY = configuration.googleApiKey;
  const AMPLITUDE_API_KEY = configuration.webAmplitudeApiKey;
  const LIBRARIES = "places,drawing,geometry,localContext,visualization".split(
    ","
  );
  const COLORS = {
    GOOGLE: configuration.googleColor,
  };
  const SENTRY_DSN = configuration.webSentryUrl;
  const SKIP_EMAIL_VERIFICATION = configuration.skipEmailVerification;
  const SKIP_MOBILE_VERIFICATION = configuration.skipMobileVerification;
  const FIREBASE_KEY = configuration.firebaseKey;
  const AUTH_DOMAIN = configuration.authDomain;
  const PROJECT_ID = configuration.projectId;
  const STORAGE_BUCKET = configuration.storageBucket;
  const MSG_SENDER_ID = configuration.msgSenderId;
  const APP_ID = configuration.appId;
  const MEASUREMENT_ID = configuration.measurementId;
   const VAPID_KEY = 
    'BJmWGbZhYIR9Sa7iP7LgpS9wud6RM2_pC3UNr4ZHeLXi6Jqry_97ODl7GfbdN5iU9eZB_Bc2hbEj9iHR7YAEnk8';

  return {
    SERVER_URL,
    WS_SERVER_URL,
    GOOGLE_CLIENT_ID,
    COLORS,
    PAYPAL_KEY,
    STRIPE_PUBLIC_KEY,
    GOOGLE_MAPS_KEY,
    AMPLITUDE_API_KEY,
    LIBRARIES,
    SENTRY_DSN,
    SKIP_EMAIL_VERIFICATION,
    SKIP_MOBILE_VERIFICATION,
    FIREBASE_KEY,
    AUTH_DOMAIN,
    PROJECT_ID,
    STORAGE_BUCKET,
    MSG_SENDER_ID,
    APP_ID,
    MEASUREMENT_ID,
    VAPID_KEY,
  };
};

export default ConfigurableValues;
