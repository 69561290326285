

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import { useGoogleLogin } from '@react-oauth/google';
import useRegistration from "../../hooks/useRegistration";
import Typography from "@mui/material/Typography";
import React from "react";
import useStyles from "./styles";
import { useTranslation } from 'react-i18next';

function GoogleLogin() {

  const {
    goolgeSuccess,
    authenticationFailure,
    loading,
    setLoading,
    loginButton,
    loginButtonSetter
  } = useRegistration();

  const { t } = useTranslation();
  const classes = useStyles();
    const login = useGoogleLogin({
        onNonOAuthError: (errors) => {
          authenticationFailure(errors)
        },
        onError: (errors) => {
          authenticationFailure(errors)
        },
        onSuccess:async (tokenResponse) => {
          try {
            const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${tokenResponse.access_token}`
              }
            });
        
            if (!response.ok) {
              throw new Error('Failed to fetch user profile');
            }
            const profileData = await response.json();
            goolgeSuccess(profileData)
            return profileData;
          } catch (error) {
            console.error('Error fetching Google profile:', error);
            throw error;
          }
        },
      });
    
    return (<>
        <Button
            variant="contained"
            fullWidth
            disableElevation
            className={`${classes.gButton} ${classes.btnBase}`}
            onClick={() => {
              login();
              loginButtonSetter("GOOGLE");
              setLoading(true);
            }}
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                <GoogleIcon />
              )
            }
          >
            {loading && loginButton === "GOOGLE" ? null : (
              <Typography
                variant="caption"
                color="textPrimary"
                align="center"
                className={`${classes.font700} ${classes.caption} ${classes.btnText}`}
              >
                {t('signInWithGoogle')}
              </Typography>
            )}
          </Button>
    </>)
}

export default GoogleLogin