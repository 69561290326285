
const ACTIVE_STATUS = ["PENDING", "PICKED", "ACCEPTED", "ASSIGNED"];
const INACTIVE_STATUS = ["DELIVERED", "COMPLETED", "CANCELLED"];
const ORDER_STATUS = ["PENDING", "ACCEPTED"];
const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const defaultLocation = {
  longitude: 19.8186982,
  latitude: 41.3275459
}

export { ACTIVE_STATUS, INACTIVE_STATUS, ORDER_STATUS, DAYS };
