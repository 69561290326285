import { Box, IconButton, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useContext } from "react";
import ConfigurationContext from "../../../context/Configuration";
import useStyles from "./styles";

function CartItem(props) {
  const { cartDetailDisplay } = props;
  const classes = useStyles();
  const theme = useTheme();
  const configuration = useContext(ConfigurationContext);

  return (
    <Box
      style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      display="flex"
      alignItems="center"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            props.removeQuantity();
          }}
          style={{ background: theme.palette.primary.main }}
        >
          <RemoveIcon
            fontSize="small"
            style={{ color: theme.palette.common.black }}
          />
        </IconButton>
        <Typography
          style={{
            ...theme.typography.caption,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.black,
            padding: "5px 10px",
            borderRadius: 5,
            border: "1px solid theme.palette.common.black",
            margin: "0 8px",
          }}
        >
          {props.quantity ? props.quantity : 0}
        </Typography>
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            props.addQuantity();
          }}
          style={{ background: theme.palette.primary.main }}
        >
          <AddIcon
            fontSize="small"
            color="primary"
            style={{ color: theme.palette.common.black }}
          />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        ml={1}
        justifyContent="flex-end"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          style={{
            flexGrow: 1,
          }}
        >
          <Box>
            <Typography className={classes.itemTextStyle}>
              {props.foodTitle ? props.foodTitle : ""}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{ color: theme.palette.common.black }}
              className={classes.subtotalText}
            >
              {` ${configuration.currencySymbol}  ${parseFloat(
                props.dealPrice
              ).toFixed(2)}`}
            </Typography>
          </Box>
        </Box>
        {/* Variation */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          style={{
            flexGrow: 1,
          }}
        >
          <Box>
            <Typography
              style={{
                color: theme.palette.common.black,
                fontSize: "0.9rem",
              }}
              className={classes.optionTextStyle}
            >
              {`# ${cartDetailDisplay?.variation?.title}`}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: theme.palette.common.black,
                fontSize: "0.9rem",
              }}
              className={classes.optionTextStyle}
            >
              {` ${configuration.currencySymbol}  ${parseFloat(
                cartDetailDisplay?.variation?.price
              ).toFixed(2)}`}
            </Typography>
          </Box>
        </Box>
        {/* Options */}
        {cartDetailDisplay?.addons &&
          cartDetailDisplay.addons.map((addon) => {
            return (
              addon?.options &&
              addon?.options.map((option) => (
                <Box
                  key={option._id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Box>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        fontSize: "0.7rem",
                      }}
                      className={classes.optionTextStyle}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`${option.title} - ${addon.title}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        fontSize: "0.7rem",
                      }}
                      className={classes.optionTextStyle}
                    >
                      {` ${configuration.currencySymbol}  ${parseFloat(
                        option.price
                      ).toFixed(2)}`}
                    </Typography>
                  </Box>
                </Box>
              ))
            );
          })}
      </Box>
    </Box>
  );
}
export default React.memo(CartItem);
